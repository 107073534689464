/* @flow */
import { Tag, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

import { onKeyEnterExec } from '@pluralcom/plural-web-utils';

import type { PrimaryTag_primaryTag } from '../../__generated__/PrimaryTag_primaryTag.graphql';

import styles from './EmojiInput.module.scss';

type Props = {
  value: string,
  primaryTag: PrimaryTag_primaryTag,
  editPrimaryTag: Function,
  tagClassname?: ?string,
};

const EmojiInput = ({
  value: inValue,
  primaryTag,
  editPrimaryTag,
  tagClassname,
}: Props) => {
  const [inputVisibleEmoji, setInputVisibleEmoji] = useState(false);
  const [value, setValue] = useState(inValue);
  const inputRef = useRef(null);

  /** focus on mount */
  useEffect(() => {
    if (inputVisibleEmoji) {
      inputRef.current.focus();
    }
  }, [inputVisibleEmoji]);

  /** Method to handle submission */
  function _handleSubmit() {
    if (value !== inValue) {
      editPrimaryTag({ id: primaryTag.id, emoji: value }, { primaryTag });
    }
  }

  if (inputVisibleEmoji) {
    return (
      <Input
        ref={inputRef}
        className={styles.emoji__inputfield}
        value={value}
        onChange={(e) => {
          setValue(
            e.target.value.replace(
              // eslint-disable-next-line no-useless-escape
              /[A-Za-z0-9~,./A;':"|\\{}\[\]§±!@#$%^&*()_+]+/g,
              '',
            ),
          );
        }}
        placeholder="(:"
        // 1 emoji = 2-5 chars - keeping extra 6 just in case
        maxLength={11}
        onBlur={() => {
          setInputVisibleEmoji(false);
        }}
        onKeyUp={onKeyEnterExec(() => {
          inputRef.current.blur();
          _handleSubmit();
        })}
      />
    );
  }

  return (
    <Tag
      className={classNames([
        tagClassname,
        {
          [styles['tag-uncommited']]: value !== inValue,
        },
      ])}
      onClick={() => setInputVisibleEmoji(true)}
    >
      {value || (
        <>
          <PlusOutlined className={styles['tag-icon']} /> Add
        </>
      )}
    </Tag>
  );
};

export default EmojiInput;
