/**
 * @generated SignedSource<<19bd49f65e03eae3c19f77b2f54a11e0>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type LogoutInput = {|
  allSessions?: ?boolean,
  clientMutationId?: ?string,
  deviceToken?: ?string,
|};
export type LogoutMutation$variables = {|
  input: LogoutInput,
|};
export type LogoutMutation$data = {|
  +logout: ?{|
    +error: ?{|
      +message: ?string,
    |},
  |},
|};
export type LogoutMutation = {|
  response: LogoutMutation$data,
  variables: LogoutMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LogoutMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LogoutPayload",
        "kind": "LinkedField",
        "name": "logout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LogoutMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LogoutPayload",
        "kind": "LinkedField",
        "name": "logout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "caca1493c79e7cc22e3ba7367650cd5f",
    "id": null,
    "metadata": {},
    "name": "LogoutMutation",
    "operationKind": "mutation",
    "text": "mutation LogoutMutation(\n  $input: LogoutInput!\n) {\n  logout(input: $input) {\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "75e5fa2a455bbe1ebed50bd1f1d5f6c2";

module.exports = ((node/*: any*/)/*: Mutation<
  LogoutMutation$variables,
  LogoutMutation$data,
>*/);
