/* @flow */

import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation BulkCreateUsersMutation($input: BulkCreateUsersInput!) {
    bulkCreateUsers(input: $input) {
      errors {
        index
        error
      }
    }
  }
`;

export default (props: { users: Array<Object>, skip_analytics?: boolean }) =>
  new Promise((resolve, reject) => {
    const variables = { input: props };

    commitMutation({
      mutation,
      variables,

      onCompleted: resolve,
      onError: reject,
    });
  });
