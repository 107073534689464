/**
 * @generated SignedSource<<7b290ee081092761fb0eefbdd1d31c9c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditSkillParametersInput = {|
  blocked?: ?boolean,
  boost?: ?boolean,
  clientMutationId?: ?string,
  do_not_suggest?: ?boolean,
  skill_id: string,
|};
export type editSkillParametersMutation$variables = {|
  input: EditSkillParametersInput,
|};
export type editSkillParametersMutation$data = {|
  +editSkillParameters: ?{|
    +error: ?{|
      +message: ?string,
      +technical_message: ?string,
    |},
    +skill: ?{|
      +blocked: ?boolean,
      +boost: ?boolean,
      +do_not_suggest: ?boolean,
      +id: string,
    |},
  |},
|};
export type editSkillParametersMutation = {|
  response: editSkillParametersMutation$data,
  variables: editSkillParametersMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Skill",
  "kind": "LinkedField",
  "name": "skill",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "do_not_suggest",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "blocked",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "technical_message",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editSkillParametersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditSkillParametersPayload",
        "kind": "LinkedField",
        "name": "editSkillParameters",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editSkillParametersMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EditSkillParametersPayload",
        "kind": "LinkedField",
        "name": "editSkillParameters",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aff6c9dd260f19005cefbb869fed3dfa",
    "id": null,
    "metadata": {},
    "name": "editSkillParametersMutation",
    "operationKind": "mutation",
    "text": "mutation editSkillParametersMutation(\n  $input: EditSkillParametersInput!\n) {\n  editSkillParameters(input: $input) {\n    skill {\n      id\n      boost\n      do_not_suggest\n      blocked\n    }\n    error {\n      technical_message\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "81f925b6cc18c766f64b350ca3eb1b97";

module.exports = ((node/*: any*/)/*: Mutation<
  editSkillParametersMutation$variables,
  editSkillParametersMutation$data,
>*/);
