import React, { lazy, Suspense } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { RelayEnvironmentProvider } from 'react-relay';

import { getEnvironment } from './graphql/Environment';
import reduxStore from './redux/store';
import { ProtectedRoute, SiteWrapper } from './components';
import LoadingLayout from './components/LoadingLayout/LoadingLayout';
import { sentryHelpers, lazyLoadRetry } from './utils';
import Auth from './services/Auth';

import './App.scss';
import routes from './routes';

const TagsViewAll = lazy(() =>
  lazyLoadRetry(() => import('./screens/TagsViewAll/TagsViewAll')),
);
const TagsViewCategories = lazy(() =>
  lazyLoadRetry(
    () => import('./screens/TagsViewCategories/TagsViewCategories'),
  ),
);
const TagsViewCurate = lazy(() =>
  lazyLoadRetry(() => import('./screens/TagsViewCurate/TagsViewCurate')),
);
const TagsViewUsed = lazy(() =>
  lazyLoadRetry(() => import('./screens/TagsViewUsed/TagsViewUsed')),
);
const Metrics = lazy(() =>
  lazyLoadRetry(() => import('./screens/Metrics/Metrics')),
);
const Tools = lazy(() => lazyLoadRetry(() => import('./screens/Tools/Tools')));
const Users = lazy(() => lazyLoadRetry(() => import('./screens/Users/Users')));
const AddUsers = lazy(() =>
  lazyLoadRetry(() => import('./screens/AddUsers/AddUsers')),
);
const UserSearches = lazy(() =>
  lazyLoadRetry(() => import('./screens/UserSearches/UserSearches')),
);
const Skills = lazy(() =>
  lazyLoadRetry(() => import('./screens/Skills/Skills')),
);
const Payments = lazy(() =>
  lazyLoadRetry(() => import('./screens/Payments/Payments')),
);
const Alerts = lazy(() =>
  lazyLoadRetry(() => import('./screens/Alerts/Alerts')),
);
const PluralBot = lazy(() =>
  lazyLoadRetry(() => import('./screens/PluralBot/PluralBot')),
);
const Login = lazy(() => lazyLoadRetry(() => import('./screens/Login/Login')));
const VerifyCode = lazy(() =>
  lazyLoadRetry(() => import('./screens/VerifyCode/VerifyCode')),
);
const Home = lazy(() => lazyLoadRetry(() => import('./screens/Home/Home')));

const Faqs = lazy(() => lazyLoadRetry(() => import('./screens/Faqs/Faqs')));

const Base64 = lazy(() =>
  lazyLoadRetry(() => import('./screens/Base64/Base64')),
);

Auth.init();
sentryHelpers.init();

/** Map of lazy loaded components */
const LOADED_COMPONENTS = {
  AddUsers,
  Alerts,
  Base64,
  Faqs,
  Home,
  Login,
  Metrics,
  Payments,
  PluralBot,
  Skills,
  TagsViewAll,
  TagsViewCategories,
  TagsViewCurate,
  TagsViewUsed,
  Tools,
  Users,
  UserSearches,
  VerifyCode,
};

/** Renders the routes based on the route config */
const _renderRoutes = () =>
  routes.map((routeObj, routeIndex) => {
    const { path, end, componentStr, privacy } = routeObj;
    const Element = LOADED_COMPONENTS[componentStr];
    const ProtectedElement =
      privacy === 'protected' ? (
        <ProtectedRoute>
          <Element />
        </ProtectedRoute>
      ) : (
        <Element />
      );
    return (
      <Route
        // eslint-disable-next-line react/no-array-index-key
        key={`${path}_${end}_${routeIndex}`}
        path={path}
        element={<>{ProtectedElement}</>}
      />
    );
  });

const App = () => (
  <Provider store={reduxStore}>
    <BrowserRouter>
      <Suspense fallback={<LoadingLayout />}>
        <RelayEnvironmentProvider environment={getEnvironment()}>
          <SiteWrapper>
            <Routes>
              {_renderRoutes()}
              {/* 404 Not Found */}
              <Route element={<div>404 Not Found</div>} />
            </Routes>
          </SiteWrapper>
        </RelayEnvironmentProvider>
      </Suspense>
    </BrowserRouter>
  </Provider>
);

export default App;
