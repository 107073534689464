/**
 * @generated SignedSource<<81281d007572eb91d281684d3b7eff2d>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { Navbar_viewer$fragmentType } from "./Navbar_viewer.graphql";
export type NavbarRefetchQuery$variables = {||};
export type NavbarRefetchQuery$data = {|
  +viewer: ?{|
    +$fragmentSpreads: Navbar_viewer$fragmentType,
  |},
|};
export type NavbarRefetchQuery = {|
  response: NavbarRefetchQuery$data,
  variables: NavbarRefetchQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavbarRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Navbar_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavbarRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "navbarProfile",
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5599bb0359e2d1bd825d23ebb2a4ff41",
    "id": null,
    "metadata": {},
    "name": "NavbarRefetchQuery",
    "operationKind": "query",
    "text": "query NavbarRefetchQuery {\n  viewer {\n    ...Navbar_viewer\n  }\n}\n\nfragment Navbar_viewer on ViewerType {\n  navbarProfile: profile {\n    id\n    username\n  }\n}\n"
  }
};

(node/*: any*/).hash = "7d3ede9a8b6bd62e26bb0fe9c4c237a8";

module.exports = ((node/*: any*/)/*: Query<
  NavbarRefetchQuery$variables,
  NavbarRefetchQuery$data,
>*/);
