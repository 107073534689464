/** @TODO added by @james should be replced in the future */
import 'regenerator-runtime/runtime';
import React, { StrictMode } from 'react';
/* eslint-disable react/no-deprecated */
import { hydrate, render } from 'react-dom';
import { polyfill } from '@pluralcom/plural-web-utils';
import App from './App';
import reportWebVitals from './reportWebVitals';

polyfill.polyfill();

const rootElement = document.getElementById('root');
const FinalApp = (
  <StrictMode>
    <App />
  </StrictMode>
);
if (rootElement?.hasChildNodes()) {
  hydrate(FinalApp, rootElement);
} else {
  render(FinalApp, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
