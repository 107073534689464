/**
 * @generated SignedSource<<bfc7f6251d6eca9e296c22f8033a8289>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { PrimaryTagAutoComplete_viewer$fragmentType } from "./PrimaryTagAutoComplete_viewer.graphql";
export type TagsOrderByType = "CREATED_AT__DESC" | "USED_AT__DESC" | "USED_AT__DESC___CREATED_AT__DESC" | "%future added value";
export type TagsSearchType = "BELONGS_TO_CATEGORY" | "BELONGS_TO_SYNONYM" | "CATEGORY" | "STANDARD" | "SYNONYM" | "%future added value";
export type TagsFilterType = {|
  boost?: ?boolean,
  used?: ?boolean,
|};
export type PrimaryTagAutoComplete_Refetch_Query$variables = {|
  filter?: ?TagsFilterType,
  first?: ?number,
  order_by?: ?TagsOrderByType,
  term?: ?string,
  type?: ?TagsSearchType,
|};
export type PrimaryTagAutoComplete_Refetch_Query$data = {|
  +viewer: ?{|
    +$fragmentSpreads: PrimaryTagAutoComplete_viewer$fragmentType,
  |},
|};
export type PrimaryTagAutoComplete_Refetch_Query = {|
  response: PrimaryTagAutoComplete_Refetch_Query$data,
  variables: PrimaryTagAutoComplete_Refetch_Query$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "term"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v5 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  {
    "kind": "Variable",
    "name": "term",
    "variableName": "term"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrimaryTagAutoComplete_Refetch_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": (v5/*: any*/),
            "kind": "FragmentSpread",
            "name": "PrimaryTagAutoComplete_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PrimaryTagAutoComplete_Refetch_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "suggestionTags",
            "args": (v5/*: any*/),
            "concreteType": "TagConnection",
            "kind": "LinkedField",
            "name": "tags",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TagEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tag",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "suggestionTags",
            "args": (v5/*: any*/),
            "filters": [
              "term",
              "filter"
            ],
            "handle": "connection",
            "key": "PrimaryTagAutoComplete_suggestionTags",
            "kind": "LinkedHandle",
            "name": "tags"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33b487f59add6e4936c6ae4797d3931d",
    "id": null,
    "metadata": {},
    "name": "PrimaryTagAutoComplete_Refetch_Query",
    "operationKind": "query",
    "text": "query PrimaryTagAutoComplete_Refetch_Query(\n  $term: String\n  $first: Int\n  $type: TagsSearchType\n  $filter: TagsFilterType\n  $order_by: TagsOrderByType\n) {\n  viewer {\n    ...PrimaryTagAutoComplete_viewer_1m94S4\n  }\n}\n\nfragment PrimaryTagAutoComplete_viewer_1m94S4 on ViewerType {\n  suggestionTags: tags(term: $term, first: $first, type: $type, filter: $filter, order_by: $order_by) {\n    edges {\n      node {\n        id\n        title\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "20925e39f3b93c1c04ad898f7fecce77";

module.exports = ((node/*: any*/)/*: Query<
  PrimaryTagAutoComplete_Refetch_Query$variables,
  PrimaryTagAutoComplete_Refetch_Query$data,
>*/);
