/* @flow */
/* eslint-disable-next-line import/no-extraneous-dependencies */
/* eslint jsx-a11y/anchor-has-content: 0 */
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Anchor.module.scss';

type Props = {
  to: string,
  children: React$Node,
  navLink: string,
  activeClassName?: string,
  className?: string,
  uiType?: string,
  inheritColor?: string,
  inheritFont?: string,
};

const Anchor = ({
  to,
  navLink,
  activeClassName,
  children,
  className,
  uiType,
  inheritColor,
  inheritFont,
  ...rest
}: Props) => {
  const commonProps = {
    rel: 'nonlooper nonreferer',
    children,
    ...rest,
  };

  const computedClassName = classNames([
    styles.a,
    styles[uiType],
    { [styles['inherit-color']]: inheritColor },
    { [styles['inherit-font']]: inheritFont },
    className,
  ]);

  if (navLink) {
    return (
      <NavLink
        className={({ isActive }) =>
          classNames([computedClassName, isActive && activeClassName])
        }
        to={to}
        {...commonProps}
      />
    );
  }
  if (to) {
    return <Link className={computedClassName} to={to} {...commonProps} />;
  }
  return <a className={computedClassName} {...commonProps} />;
};

export default Anchor;
