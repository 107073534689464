/* @flow */
import React, { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import intersection from 'lodash/intersection';
import { localStorageService } from '@pluralcom/plural-web-utils';

/** Default authentication route to be routed to */
const DEFAULT_AUTH_ROUTE = '/login';

/**
 * Protected Route Component has the exact API as React-Router's Browser Router.
 * Except that if the User is not authenticated, they will be redirected to the auth page
 * With the redirection, information about the protected route is passed
 * to the auth page to route to it post auth
 */
const ProtectedRoute = ({
  children,
  redirectTo = {
    pathname: DEFAULT_AUTH_ROUTE,
  },
  disableRedirect,
  accessRoles: inAccessRoles = ['user'],
}: {
  /** Children */
  children?: ReactNode;
  /** Redirect to this route if the user is not authenticated */
  redirectTo?:
    | {
        /** Path to redirect to */
        pathname: string;
        /** State to be passed to the redirected route */
        state?: any;
      }
    | string;
  /** Disable redirection */
  disableRedirect?: boolean;
  /** Access roles */
  accessRoles?: Array<string>;
}) => {
  const location = useLocation();
  const accessRoles = inAccessRoles.filter(Boolean);
  if (
    intersection(accessRoles, localStorageService.getItem('user_roles') || [])
      .length !== accessRoles.length &&
    !disableRedirect
  ) {
    let to = typeof redirectTo === 'string' ? redirectTo : DEFAULT_AUTH_ROUTE;
    let state = { from: location };
    if (typeof redirectTo === 'object') {
      to = redirectTo.pathname;
      if (redirectTo.state) {
        state = redirectTo.state;
      }
    }
    return <Navigate to={to} state={state} />;
  }
  return <>{children}</>;
};

export { ProtectedRoute as PureProtectedRoute };

export default ProtectedRoute;
