/* @flow */

/**
 * Please preserve the alphabetic order
 */

/** Solid */
import { definition as fasBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { definition as fasBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { definition as fasChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { definition as fasDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { definition as fasExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { definition as fasGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { definition as fasHammer } from '@fortawesome/free-solid-svg-icons/faHammer';
import { definition as fasHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag';
import { definition as fasTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { definition as fasRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
import { definition as fasSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { definition as fasSearchLocation } from '@fortawesome/free-solid-svg-icons/faSearchLocation';
import { definition as fasShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt';
import { definition as fasSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { definition as fasSitemap } from '@fortawesome/free-solid-svg-icons/faSitemap';
import { definition as fasStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { definition as fasTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { definition as fasUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { definition as fasQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { definition as fasTrash } from '@fortawesome/free-solid-svg-icons/faTrash';

/** Regular */
/** Thin */

const libraryIcons = [
  /** Solid */
  fasBan,
  fasBell,
  fasChartBar,
  fasDollarSign,
  fasExclamationTriangle,
  fasGlobe,
  fasHammer,
  fasHashtag,
  fasTag,
  fasRobot,
  fasSearchLocation,
  fasSignOutAlt,
  fasSitemap,
  fasSearchLocation,
  fasSpinner,
  fasShieldAlt,
  fasStar,
  fasTimes,
  fasUsers,
  fasQuestionCircle,
  fasTrash,
];

export default libraryIcons;
