/**
 * @generated SignedSource<<6a3b47c78388767974a1c2bdb85be38a>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type GenderType = "FEMALE" | "MALE" | "NOT_SPECIFIED" | "%future added value";
export type VerifyPinInput = {|
  auth_reqid: string,
  clientMutationId?: ?string,
  email?: ?string,
  phone?: ?string,
  pin: string,
  with_auth?: ?boolean,
|};
export type VerifyPinMutation$variables = {|
  input: VerifyPinInput,
|};
export type VerifyPinMutation$data = {|
  +verifyPin: ?{|
    +error: ?{|
      +message: ?string,
    |},
    +profile: ?{|
      +avatar: ?{|
        +id: string,
        +smallThumbnail: ?string,
        +thumbnail: ?string,
      |},
      +birthdate: ?string,
      +created_at: any,
      +email: ?string,
      +first_name: ?string,
      +gender: ?GenderType,
      +id: string,
      +last_name: ?string,
      +name: ?string,
      +phone: ?string,
      +published_at: ?any,
      +username: ?string,
      +website: ?string,
    |},
    +roles: ?$ReadOnlyArray<?string>,
  |},
|};
export type VerifyPinMutation = {|
  response: VerifyPinMutation$data,
  variables: VerifyPinMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "profile",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "smallThumbnail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thumbnail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "birthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "published_at",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "roles",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyPinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyPinPayload",
        "kind": "LinkedField",
        "name": "verifyPin",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyPinMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VerifyPinPayload",
        "kind": "LinkedField",
        "name": "verifyPin",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "77dc80a48a1f182f6d44deebf5e2d714",
    "id": null,
    "metadata": {},
    "name": "VerifyPinMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyPinMutation(\n  $input: VerifyPinInput!\n) {\n  verifyPin(input: $input) {\n    profile {\n      id\n      username\n      name\n      first_name\n      last_name\n      avatar {\n        id\n        smallThumbnail\n        thumbnail\n      }\n      email\n      phone\n      website\n      gender\n      birthdate\n      created_at\n      published_at\n    }\n    roles\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "eb52dc5ff05da16410021f769850fdcb";

module.exports = ((node/*: any*/)/*: Mutation<
  VerifyPinMutation$variables,
  VerifyPinMutation$data,
>*/);
