/* @flow */
import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const addFAQuestion = ({ question }: { question: string }): Promise =>
  new Promise((resolve, reject) => {
    const mutation = graphql`
      mutation AddFaqQuestionMutation($input: AddFAQuestionInput!) {
        addFAQuestion(input: $input) {
          error {
            id
            title
            message
          }
          newQuestionEdge {
            node {
              id
              question
              general
              connected_tags(first: 10) {
                edges {
                  node {
                    id
                    skillTag {
                      id
                      title
                      synonyms {
                        id
                        title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    const variables = {
      input: {
        question,
      },
    };

    const optimisticResponse = {
      addFAQuestion: {
        error: null,
        newQuestionEdge: {
          node: {
            id: `OPUP_FAQuestion_${Math.random().toString(36).substr(2, 9)}`,
            question,
          },
        },
      },
    };

    const configs = [
      {
        type: 'RANGE_ADD',
        parentID: 'client:root:viewer',
        connectionInfo: [
          {
            key: 'FaqsView_questions',
            rangeBehavior: 'prepend',
          },
        ],
        edgeName: 'newQuestionEdge',
      },
    ];

    commitMutation({
      mutation,
      variables,
      optimisticResponse,
      configs,
      onCompleted: resolve,
      onError: reject,
    });
  });

export default addFAQuestion;
