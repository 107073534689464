/**
 * @generated SignedSource<<ef0edeaa1a6810245539e957eabaac7c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type PaymentStatus = "DECLINED" | "FAILED" | "PAID" | "PAID_OUT" | "PENDING" | "REFUNDED" | "REFUND_REQUEST" | "REQUESTED" | "REQUEST_CANCELLED" | "%future added value";
export type UpdatePaymentRefundInput = {|
  clientMutationId?: ?string,
  payment_id: string,
  refund: boolean,
  refund_reason?: ?string,
  refund_reject_reason?: ?string,
|};
export type UpdatePaymentRefundMutation$variables = {|
  input: UpdatePaymentRefundInput,
|};
export type UpdatePaymentRefundMutation$data = {|
  +updatePaymentRefund: ?{|
    +error: ?{|
      +message: ?string,
    |},
    +payment: ?{|
      +approved_by_system_at: ?any,
      +id: string,
      +is_support_required: ?boolean,
      +refund_reason: ?string,
      +refund_request_cancelled_at: ?any,
      +refund_request_declined_at: ?any,
      +refunded_at: ?any,
      +refunded_by: ?string,
      +refunded_by_id: ?string,
      +status: PaymentStatus,
    |},
  |},
|};
export type UpdatePaymentRefundMutation = {|
  response: UpdatePaymentRefundMutation$data,
  variables: UpdatePaymentRefundMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Payment",
  "kind": "LinkedField",
  "name": "payment",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approved_by_system_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refund_request_declined_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refund_request_cancelled_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refunded_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refunded_by",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refunded_by_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refund_reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_support_required",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePaymentRefundMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePaymentRefundPayload",
        "kind": "LinkedField",
        "name": "updatePaymentRefund",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePaymentRefundMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePaymentRefundPayload",
        "kind": "LinkedField",
        "name": "updatePaymentRefund",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7317b6ffbabeeefe30efc2f58263656",
    "id": null,
    "metadata": {},
    "name": "UpdatePaymentRefundMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePaymentRefundMutation(\n  $input: UpdatePaymentRefundInput!\n) {\n  updatePaymentRefund(input: $input) {\n    payment {\n      id\n      status\n      approved_by_system_at\n      refund_request_declined_at\n      refund_request_cancelled_at\n      refunded_at\n      refunded_by\n      refunded_by_id\n      refund_reason\n      is_support_required\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "636b0e5efa3c71a1230ed6ac13ae95f3";

module.exports = ((node/*: any*/)/*: Mutation<
  UpdatePaymentRefundMutation$variables,
  UpdatePaymentRefundMutation$data,
>*/);
