/**
 * @generated SignedSource<<6fb016f646a26fa2e05771e86faba058>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddFAQuestionInput = {|
  clientMutationId?: ?string,
  question: string,
|};
export type AddFaqQuestionMutation$variables = {|
  input: AddFAQuestionInput,
|};
export type AddFaqQuestionMutation$data = {|
  +addFAQuestion: ?{|
    +error: ?{|
      +id: string,
      +message: ?string,
      +title: ?string,
    |},
    +newQuestionEdge: ?{|
      +node: ?{|
        +connected_tags: ?{|
          +edges: ?$ReadOnlyArray<?{|
            +node: ?{|
              +id: string,
              +skillTag: {|
                +id: string,
                +synonyms: ?$ReadOnlyArray<?{|
                  +id: string,
                  +title: ?string,
                |}>,
                +title: ?string,
              |},
            |},
          |}>,
        |},
        +general: ?boolean,
        +id: string,
        +question: string,
      |},
    |},
  |},
|};
export type AddFaqQuestionMutation = {|
  response: AddFaqQuestionMutation$data,
  variables: AddFaqQuestionMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddFAQuestionPayload",
    "kind": "LinkedField",
    "name": "addFAQuestion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FAQuestionEdge",
        "kind": "LinkedField",
        "name": "newQuestionEdge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FAQuestion",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "question",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "general",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  }
                ],
                "concreteType": "FAQuestionTagConnection",
                "kind": "LinkedField",
                "name": "connected_tags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FAQuestionTagEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FAQuestionTag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Tag",
                            "kind": "LinkedField",
                            "name": "skillTag",
                            "plural": false,
                            "selections": [
                              (v1/*: any*/),
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Tag",
                                "kind": "LinkedField",
                                "name": "synonyms",
                                "plural": true,
                                "selections": [
                                  (v1/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "connected_tags(first:10)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddFaqQuestionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddFaqQuestionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "a8af1e42e017c8f19069947da7b75867",
    "id": null,
    "metadata": {},
    "name": "AddFaqQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation AddFaqQuestionMutation(\n  $input: AddFAQuestionInput!\n) {\n  addFAQuestion(input: $input) {\n    error {\n      id\n      title\n      message\n    }\n    newQuestionEdge {\n      node {\n        id\n        question\n        general\n        connected_tags(first: 10) {\n          edges {\n            node {\n              id\n              skillTag {\n                id\n                title\n                synonyms {\n                  id\n                  title\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "8a56f86245b3f90bb2f869ddd9732edf";

module.exports = ((node/*: any*/)/*: Mutation<
  AddFaqQuestionMutation$variables,
  AddFaqQuestionMutation$data,
>*/);
