/**
 * @generated SignedSource<<fbf79251adf875467c2db7c9f7090036>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveTagFromFAQuestionInput = {|
  clientMutationId?: ?string,
  question?: ?string,
  question_id?: ?string,
  tag_id?: ?string,
  tag_title?: ?string,
|};
export type RemoveTagFromFAQuestionMutation$variables = {|
  input: RemoveTagFromFAQuestionInput,
|};
export type RemoveTagFromFAQuestionMutation$data = {|
  +removeTagFromFAQuestion: ?{|
    +deleted_tag_faQuestion_id: ?string,
    +error: ?{|
      +id: string,
      +message: ?string,
      +technical_message: ?string,
      +title: ?string,
      +type: ?string,
    |},
    +question: ?{|
      +id: string,
    |},
  |},
|};
export type RemoveTagFromFAQuestionMutation = {|
  response: RemoveTagFromFAQuestionMutation$data,
  variables: RemoveTagFromFAQuestionMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RemoveTagFromFAQuestionPayload",
    "kind": "LinkedField",
    "name": "removeTagFromFAQuestion",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "error",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "technical_message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FAQuestion",
        "kind": "LinkedField",
        "name": "question",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted_tag_faQuestion_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveTagFromFAQuestionMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveTagFromFAQuestionMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a0f8d160e823555c4aa28ff0689cb304",
    "id": null,
    "metadata": {},
    "name": "RemoveTagFromFAQuestionMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveTagFromFAQuestionMutation(\n  $input: RemoveTagFromFAQuestionInput!\n) {\n  removeTagFromFAQuestion(input: $input) {\n    error {\n      id\n      title\n      message\n      technical_message\n      type\n    }\n    question {\n      id\n    }\n    deleted_tag_faQuestion_id\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "ead2f3af66f897a1f672492167afd72c";

module.exports = ((node/*: any*/)/*: Mutation<
  RemoveTagFromFAQuestionMutation$variables,
  RemoveTagFromFAQuestionMutation$data,
>*/);
