/* @flow weak */

import graphql from 'babel-plugin-relay/macro';

import { commitMutation } from '../..';

const mutation = graphql`
  mutation deleteUserMutation($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      deletedUserId
      error {
        message
      }
    }
  }
`;

export default ({ user_id, full_erase }) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        user_id,
        full_erase,
      },
    };

    const optimisticResponse = {
      error: null,
      deletedUserId: user_id,
    };

    commitMutation({
      mutation,
      variables,
      optimisticResponse,

      onCompleted: resolve,
      onError: reject,
    });
  });
