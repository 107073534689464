/**
 * @generated SignedSource<<924b385a38e0c1afaaeedd34d397354c>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Query } from 'relay-runtime';
import type { Navbar_viewer$fragmentType } from "./../../../components/Navbar/__generated__/Navbar_viewer.graphql";
export type NavbarQuery$variables = {||};
export type NavbarQuery$data = {|
  +viewer: ?{|
    +$fragmentSpreads: Navbar_viewer$fragmentType,
  |},
|};
export type NavbarQuery = {|
  response: NavbarQuery$data,
  variables: NavbarQuery$variables,
|};
*/

var node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavbarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Navbar_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavbarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ViewerType",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": "navbarProfile",
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9b657809411c4f4d3a2c544f3c4b219",
    "id": null,
    "metadata": {},
    "name": "NavbarQuery",
    "operationKind": "query",
    "text": "query NavbarQuery {\n  viewer {\n    ...Navbar_viewer\n  }\n}\n\nfragment Navbar_viewer on ViewerType {\n  navbarProfile: profile {\n    id\n    username\n  }\n}\n"
  }
};

(node/*: any*/).hash = "4648d0fd2b67a3655b6058c91c21a6d8";

module.exports = ((node/*: any*/)/*: Query<
  NavbarQuery$variables,
  NavbarQuery$data,
>*/);
