/* @flow */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useLocation } from 'react-router-dom';
import { logger, environment } from '../../utils';
import { useScrollToTop } from '../../hooks';

type Props = {
  children: React$Node,
};

/**
 * The SiteWrapper component is responsible for
 * a lot of root events.
 *
 * - Console welcome logs upon mounting
 * - Scroll smoothing to top upon children change (route change)
 * - Sentry error tracking and logging upon catching an error
 */
const SiteWrapper = ({ children }: Props) => {
  const location = useLocation();
  /** initial mount effect */
  useEffect(() => {
    logger.info('REACT_APP info: ', {
      version: environment.getAppVersion(),
      host_env: environment.getHostEnv(),
    });
  }, []);

  /** scroll to top effect */
  useScrollToTop({ location });

  /**
   * We render react-helmet again to override previous renders from previous screens
   */
  return (
    <div className="site-wrapper" id="site-wrapper">
      <Helmet titleTemplate="%s | Plural Admin">
        <title>Home</title>
      </Helmet>
      {children}
    </div>
  );
};

export { SiteWrapper as PureSiteWrapper };

export default SiteWrapper;
