/**
 * @generated SignedSource<<1b834b7dba6d13257f7140fa2ca4df4b>>
 * @flow
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePrimaryTagInput = {|
  block?: ?boolean,
  boost?: ?boolean,
  clientMutationId?: ?string,
  do_not_suggest?: ?boolean,
  emoji?: ?string,
  remote?: ?boolean,
  title: string,
|};
export type CreatePrimaryTagMutation$variables = {|
  input: CreatePrimaryTagInput,
|};
export type CreatePrimaryTagMutation$data = {|
  +createPrimaryTag: ?{|
    +error: ?{|
      +message: ?string,
    |},
    +primaryTagEdge: ?{|
      +cursor: ?string,
      +node: ?{|
        +block: ?boolean,
        +boost: ?boolean,
        +categories: ?$ReadOnlyArray<?{|
          +id: string,
          +title: ?string,
        |}>,
        +corrected_to_tag: ?{|
          +id: string,
          +title: ?string,
        |},
        +do_not_suggest: ?boolean,
        +emoji: ?string,
        +id: string,
        +remote: ?boolean,
        +skillTags: ?$ReadOnlyArray<?{|
          +id: string,
          +title: ?string,
        |}>,
        +slug: ?string,
        +synonyms: ?$ReadOnlyArray<?{|
          +id: string,
          +title: ?string,
        |}>,
        +title: ?string,
        +used_at: ?any,
      |},
    |},
    +tagsEdges: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +block: ?boolean,
          +boost: ?boolean,
          +categories: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +corrected_to_tag: ?{|
            +id: string,
            +title: ?string,
          |},
          +do_not_suggest: ?boolean,
          +id: string,
          +remote: ?boolean,
          +skillTags: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +slug: ?string,
          +synonyms: ?$ReadOnlyArray<?{|
            +id: string,
            +title: ?string,
          |}>,
          +title: ?string,
          +used_at: ?any,
        |},
      |}>,
    |},
  |},
|};
export type CreatePrimaryTagMutation = {|
  response: CreatePrimaryTagMutation$data,
  variables: CreatePrimaryTagMutation$variables,
|};
*/

var node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "remote",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "do_not_suggest",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "boost",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "block",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "used_at",
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "corrected_to_tag",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "categories",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "synonyms",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "skillTags",
  "plural": true,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "CreateTagEdge",
  "kind": "LinkedField",
  "name": "primaryTagEdge",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cursor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "TagConnection",
  "kind": "LinkedField",
  "name": "tagsEdges",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TagEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tag",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v11/*: any*/),
            (v12/*: any*/),
            (v13/*: any*/),
            (v14/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePrimaryTagPayload",
        "kind": "LinkedField",
        "name": "createPrimaryTag",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePrimaryTagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreatePrimaryTagPayload",
        "kind": "LinkedField",
        "name": "createPrimaryTag",
        "plural": false,
        "selections": [
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Error",
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v17/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a04ae132b067bc344fefa4a7aa6f2e28",
    "id": null,
    "metadata": {},
    "name": "CreatePrimaryTagMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePrimaryTagMutation(\n  $input: CreatePrimaryTagInput!\n) {\n  createPrimaryTag(input: $input) {\n    primaryTagEdge {\n      node {\n        id\n        title\n        slug\n        emoji\n        remote\n        do_not_suggest\n        boost\n        block\n        used_at\n        corrected_to_tag {\n          id\n          title\n        }\n        categories {\n          id\n          title\n        }\n        synonyms {\n          id\n          title\n        }\n        skillTags {\n          id\n          title\n        }\n      }\n      cursor\n    }\n    tagsEdges {\n      edges {\n        node {\n          id\n          title\n          slug\n          remote\n          do_not_suggest\n          boost\n          block\n          used_at\n          corrected_to_tag {\n            id\n            title\n          }\n          categories {\n            id\n            title\n          }\n          synonyms {\n            id\n            title\n          }\n          skillTags {\n            id\n            title\n          }\n        }\n      }\n    }\n    error {\n      message\n      id\n    }\n  }\n}\n"
  }
};
})();

(node/*: any*/).hash = "4373c53e55a612e412e509329e50172c";

module.exports = ((node/*: any*/)/*: Mutation<
  CreatePrimaryTagMutation$variables,
  CreatePrimaryTagMutation$data,
>*/);
