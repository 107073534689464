/* @flow */

import graphql from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';

import { slugify } from '@pluralcom/plural-js-utils';

import { commitMutation } from '../..';
import {
  setNodeValues,
  setNodeLinkedRecords,
  getSourceRecords,
} from '../../relayUtils';

const mutation = graphql`
  mutation CreatePrimaryTagMutation($input: CreatePrimaryTagInput!) {
    createPrimaryTag(input: $input) {
      primaryTagEdge {
        node {
          id
          title
          slug
          emoji
          remote
          do_not_suggest
          boost
          block
          used_at
          corrected_to_tag {
            id
            title
          }
          categories {
            id
            title
          }
          synonyms {
            id
            title
          }
          skillTags {
            id
            title
          }
        }
        cursor
      }
      tagsEdges {
        edges {
          node {
            id
            title
            slug
            remote
            do_not_suggest
            boost
            block
            used_at
            corrected_to_tag {
              id
              title
            }
            categories {
              id
              title
            }
            synonyms {
              id
              title
            }
            skillTags {
              id
              title
            }
          }
        }
      }
      error {
        message
      }
    }
  }
`;

const records = getSourceRecords();

const sharedUpdater = (store, newEdges, tagColumns?: Object) => {
  const rootField = store.getRoot();
  const viewerField = rootField.getLinkedRecord('viewer');

  const boost =
    newEdges[0]?.getLinkedRecord('node').getValue('boost') ??
    tagColumns.boost ??
    false;

  const _addToConnection = ([connectionName, filters]) => {
    const connection = ConnectionHandler.getConnection(
      viewerField,
      connectionName,
      filters,
    );
    if (connection) {
      newEdges.forEach((newEdge) => {
        ConnectionHandler.insertEdgeBefore(connection, newEdge);
      });
      connection.setValue(
        connection.getValue('totalCount') + newEdges.length,
        'totalCount',
      );
    }
  };

  [
    ['TagsViewAll_tags'],
    ['TagsViewUsed_tags'],
    boost ? ['TagsViewCurate_tags'] : null,
    ['PrimaryTagAutoComplete_suggestionTags', { term: '' }],
    boost
      ? [
          'PrimaryTagAutoComplete_suggestionTags',
          { term: '', filter: { boost: true } },
        ]
      : null,
  ]
    .filter(Boolean)
    .forEach((connectionName) => _addToConnection(connectionName));
};

export default ({
  title,
  emoji,
  remote = false,
  boost = false,
  do_not_suggest = false,
  block = false,
}: {
  title: string,
  emoji?: ?string,
  remote?: boolean,
  boost?: boolean,
  do_not_suggest?: boolean,
  block?: boolean,
}) =>
  new Promise((resolve, reject) => {
    const variables = {
      input: {
        title,
        emoji,
        remote,
        boost,
        do_not_suggest,
        block,
      },
    };

    commitMutation({
      mutation,
      variables,
      optimisticUpdater: (store) => {
        if (
          Object.keys(records)
            .map((key) => records[key])
            .filter(
              (item) =>
                item.__typename === 'Tag' && item.slug === slugify(title),
            ).length === 0
        ) {
          const tempID = `${Math.random()}`.substring(2, 10);
          const id = `client:newPrimaryTag:${tempID}`;
          const node = store.create(id, 'Tag');

          setNodeValues(node, {
            title,
            id,
            slug: slugify(title),
            remote,
            boost,
            do_not_suggest,
            block,
            used_at: new Date().toISOString(),
          });
          setNodeLinkedRecords(node, {
            categories: [],
            synonyms: [],
            skillTags: [],
            tags_with_this_category: [],
          });

          const newEdge = store.create(`OPUP:Tag:${tempID}`, 'primaryTagEdge');
          newEdge.setLinkedRecord(node, 'node');

          sharedUpdater(store, [newEdge], { boost });
        }
      },
      updater: (store) => {
        const payload = store.getRootField('createPrimaryTag');
        const error = payload.getLinkedRecord('error');

        const tagsEdges = payload
          .getLinkedRecord('tagsEdges')
          ?.getLinkedRecords('edges');

        if (!error) {
          sharedUpdater(store, tagsEdges, { boost });
        }
      },
      onCompleted: resolve,
      onError: reject,
    });
  });
